var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames"
          }
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { name: "1" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "div",
                  { style: { fontSize: "16px", paddingLeft: "20px" } },
                  [
                    _vm._v(" 点这里从现有数据库创建代码 "),
                    _c("i", { staticClass: "header-icon el-icon-thumb" })
                  ]
                )
              ]),
              _c(
                "el-form",
                {
                  ref: "getTableForm",
                  attrs: {
                    inline: true,
                    model: _vm.dbform,
                    "label-width": "120px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "数据库名", prop: "structName" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            placeholder: "请选择数据库"
                          },
                          on: { change: _vm.getTable },
                          model: {
                            value: _vm.dbform.dbName,
                            callback: function($$v) {
                              _vm.$set(_vm.dbform, "dbName", $$v)
                            },
                            expression: "dbform.dbName"
                          }
                        },
                        _vm._l(_vm.dbOptions, function(item) {
                          return _c("el-option", {
                            key: item.database,
                            attrs: {
                              label: item.database,
                              value: item.database
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "表名", prop: "structName" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: !_vm.dbform.dbName,
                            filterable: "",
                            placeholder: "请选择表"
                          },
                          model: {
                            value: _vm.dbform.tableName,
                            callback: function($$v) {
                              _vm.$set(_vm.dbform, "tableName", $$v)
                            },
                            expression: "dbform.tableName"
                          }
                        },
                        _vm._l(_vm.tableOptions, function(item) {
                          return _c("el-option", {
                            key: item.tableName,
                            attrs: {
                              label: item.tableName,
                              value: item.tableName
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.getColumn }
                        },
                        [_vm._v("使用此表创建")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-form",
        {
          ref: "autoCodeForm",
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            "label-width": "120px",
            inline: true
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Struct名称", prop: "structName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "首字母自动转换大写" },
                model: {
                  value: _vm.form.structName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "structName", $$v)
                  },
                  expression: "form.structName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "tableName", prop: "tableName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "指定表名（非必填）" },
                model: {
                  value: _vm.form.tableName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "tableName", $$v)
                  },
                  expression: "form.tableName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Struct简称", prop: "abbreviation" } },
            [
              _c("el-input", {
                attrs: { placeholder: "简称会作为入参对象名和路由group" },
                model: {
                  value: _vm.form.abbreviation,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "abbreviation", $$v)
                  },
                  expression: "form.abbreviation"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Struct中文名称", prop: "description" } },
            [
              _c("el-input", {
                attrs: { placeholder: "中文描述作为自动api描述" },
                model: {
                  value: _vm.form.description,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "文件名称", prop: "packageName" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.packageName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "packageName", $$v)
                  },
                  expression: "form.packageName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "自动创建api" } },
            [
              _c("el-checkbox", {
                model: {
                  value: _vm.form.autoCreateApiToSql,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "autoCreateApiToSql", $$v)
                  },
                  expression: "form.autoCreateApiToSql"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "自动移动文件" } },
            [
              _c("el-checkbox", {
                model: {
                  value: _vm.form.autoMoveFile,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "autoMoveFile", $$v)
                  },
                  expression: "form.autoMoveFile"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "button-box clearflex" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.editAndAddField()
                }
              }
            },
            [_vm._v("新增Field")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.form.fields, border: "", stripe: "" } },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序列", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "fieldName", label: "Field名" }
          }),
          _c("el-table-column", {
            attrs: { prop: "fieldDesc", label: "中文名" }
          }),
          _c("el-table-column", {
            attrs: { prop: "fieldJson", label: "FieldJson" }
          }),
          _c("el-table-column", {
            attrs: { prop: "fieldType", label: "Field数据类型", width: "130" }
          }),
          _c("el-table-column", {
            attrs: { prop: "dataType", label: "数据库字段类型", width: "130" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "dataTypeLong",
              label: "数据库字段长度",
              width: "130"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "columnName", label: "数据库字段", width: "130" }
          }),
          _c("el-table-column", {
            attrs: { prop: "comment", label: "数据库字段描述", width: "130" }
          }),
          _c("el-table-column", {
            attrs: { prop: "fieldSearchType", label: "搜索条件", width: "130" }
          }),
          _c("el-table-column", {
            attrs: { prop: "dictType", label: "字典", width: "130" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.editAndAddField(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          disabled: scope.$index == 0
                        },
                        on: {
                          click: function($event) {
                            return _vm.moveUpField(scope.$index)
                          }
                        }
                      },
                      [_vm._v("上移")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          disabled: scope.$index + 1 == _vm.form.fields.length
                        },
                        on: {
                          click: function($event) {
                            return _vm.moveDownField(scope.$index)
                          }
                        }
                      },
                      [_vm._v("下移")]
                    ),
                    _c(
                      "el-popover",
                      {
                        attrs: { placement: "top" },
                        model: {
                          value: scope.row.visible,
                          callback: function($$v) {
                            _vm.$set(scope.row, "visible", $$v)
                          },
                          expression: "scope.row.visible"
                        }
                      },
                      [
                        _c("p", [_vm._v("确定删除吗？")]),
                        _c(
                          "div",
                          {
                            staticStyle: { "text-align": "right", margin: "0" }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function($event) {
                                    scope.row.visible = false
                                  }
                                }
                              },
                              [_vm._v("取消")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteField(scope.$index)
                                  }
                                }
                              },
                              [_vm._v("确定")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              size: "mini",
                              type: "danger",
                              icon: "el-icon-delete"
                            },
                            slot: "reference"
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-tag", { attrs: { type: "danger" } }, [
        _vm._v(
          "id , created_at , updated_at , deleted_at 会自动生成请勿重复创建"
        )
      ]),
      _c(
        "div",
        { staticClass: "button-box clearflex" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.enterForm } },
            [_vm._v("生成代码")]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "组件内容", visible: _vm.dialogFlag },
          on: {
            "update:visible": function($event) {
              _vm.dialogFlag = $event
            }
          }
        },
        [
          _vm.dialogFlag
            ? _c("FieldDialog", {
                ref: "fieldDialog",
                attrs: { dialogMiddle: _vm.dialogMiddle }
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.enterDialog } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }